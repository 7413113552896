import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Image from '../components/FeaturedImage'
import SubCats from '../components/subCats'
import Grid from '../components/grid'
import GridItem from '../components/gridItem'

const IndexPage = ({data}) => {
  return (
    <Layout pageTitle={data.wpLattecat.name}>

      <div className="mb-24">
        <SubCats cats={data.wpLattecat.wpChildren.nodes} />
      </div>

      <Grid>
        { data.wpLattecat.latteproducts.nodes.map( product => {
          return (
            <GridItem
              key={product.id}
              uri={`/product/${product.slug}/`}
              title={ product.title }
            >
              <Image product={product}/>
            </GridItem>
          )
        })}
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpLattecat(id: {eq: $id}) {
      id
      name
      slug
      latteproducts {
        nodes {
          id
          title
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      wpChildren {
        nodes {
          id
          name
          slug
        }
      }
    }
  }
`

export default IndexPage
